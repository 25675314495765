<template>
  <!-- 资产项目 -->
  <div style="margin-top: 20px;">
    <div class="box-card" style="text-align: left;">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm" ref="queryFormRef"
        @submit.native.prevent>
        <el-form-item label="资产项目：">
          <el-input maxlength="15" v-model="queryForm.project_name" @keyup.enter.native="onSubmit()"
            @input="(e) => (queryForm.project_name = validSpace(e))" placeholder="请输入资产项目"></el-input>
        </el-form-item>
        <el-form-item label="集团：" v-if='this.getUserInfo.user_name === "admin"'>
          <el-select popper-class="my-select" v-model="queryForm.group_id" filterable clearable default-first-option
            placeholder="请选择集团" @change="onSubmit">
            <el-option v-for="item in allGroupList" :key="item._id" :label="item.group_name" :value="item._id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="资产项目">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('12b60fc9c972460db14f6c34')">删除
            </lbButton>
            <lbButton icon="xinzeng" @click="openAddPage"
              v-if='this.getUserInfo.user_name != "admin" && isShowBtn("5575dce8b0714d119959a776")'>新增</lbButton>
          </div>
        </template>
        <div>
          <!-- 表格 -->
           <!-- 使用插件生产表格 -->
           <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
             <template slot-scope="{row}" slot="inspectionCount">
              <el-link type="primary" style="margin-right: 10px" v-if="row.inspectionCount > 0"
                  @click="openLookInspection(row)">{{ row.inspectionCount }}</el-link>
                <span v-else>{{ row.inspectionCount }}</span>
            </template>
             <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
              <template slot-scope="{row}" slot="menu">
                <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditPage(row)"
                  v-if="isShowBtn('7d2b09b8939e45a695465a0d')"></lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
            <el-table-column prop="project_name" label="资产项目"></el-table-column>
            <el-table-column prop="inspectionCount" label="巡检项">
              <template slot-scope="scope">
                <el-link type="primary" style="margin-right: 10px" v-if="scope.row.inspectionCount > 0"
                  @click="openLookInspection(scope.row)">{{ scope.row.inspectionCount }}</el-link>
                <span v-else>{{ scope.row.inspectionCount }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="300">
              <template slot-scope="scope">
                {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" width="300">
              <template slot-scope="scope">
                <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditPage(scope.row)"
                  v-if="isShowBtn('7d2b09b8939e45a695465a0d')"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 新增、编辑项目对话框 -->
    <lebo-dialog :title="titleDialog" :isShow="addAndEditDialog" width="30%" @close="addAndEditDialogClose" footerSlot>
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="项目名称：" prop="project_name">
          <el-input v-model="addForm.project_name" maxlength="15" placeholder="请输入项目名称"
            @input="(e) => (addForm.project_name = validSpace(e))"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="addAndEditDialog = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="sendForm">保 存</lbButton>
      </span>
    </lebo-dialog>
    <!-- 巡检项对话框 -->
    <lebo-dialog title="查看巡检项" :isShow="isInspectionDialog" width="40%" @close="isInspectionDialog = false" footerSlot closeOnClickModal>
      <el-table :data="inspectionData" tooltip-effect="dark" style="width: 100%" border stripe height="450">
        <el-table-column prop="inspection_name" label="巡检项名称"></el-table-column>
        <el-table-column prop="inspection_content" label="检查内容" min-width="200">
          <template slot-scope="scope">
            <div>
              <span v-html="scope.row.inspection_content.replace(/\r\n|\n|\r/g, '<br/>')"></span>
              <el-link type="primary" v-if="scope.row.pic.length > 0"
                @click="showPreviewPicture(scope.row.pic)">【查看】</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </lebo-dialog>
    <!-- 图片预览 -->
    <el-image-viewer v-if="isShowPreviewPicture" :on-close="onClosePreviewPicture" :url-list="previewPictureList"
      :z-index="9999" />
  </div>
</template>

<script>
import { getAssetsProjectList, getAssetInspectionItemList, getAllGropSelectList, addAssetsProject, updateAssetsProject, delAssetsProject } from '@/api/assetsManagement'
import { mapGetters } from 'vuex'
// // 导入组件
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components: {
    ElImageViewer
  },
  data () {
    return {
      queryForm: {
        project_name: '',
        group_id: '',
        PageIndex: 1,
        PageSize: 10
      },
      // 所有集团下拉列表
      allGroupList: [],
      tableData: [],
      total: 0,
      multipleSelection: [],
      // 是否展示新增、编辑项目对话框
      addAndEditDialog: false,
      titleDialog: '',
      addForm: {
        Id: '',
        project_name: ''
      },
      addFormRules: {
        project_name: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ]
      },
      // 是否展示查看巡检项对话框
      isInspectionDialog: false,
      inspectionData: [],
      // 展示图片预览
      isShowPreviewPicture: false,
      // 预览图片
      previewPictureList: [],
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '资产项目',
            prop: 'project_name'
          }, {
            label: '巡检项',
            prop: 'inspectionCount',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  computed: {
    ...mapGetters(['getUserInfo'])
  },
  created () {
  },
  mounted () {
    if (this.getUserInfo.user_name === 'admin') {
      this.fnGetAllGropSelectList()
    }
    this.fnGetAssetsProjectList()
  },
  methods: {
    // 获取集团下拉列
    async fnGetAllGropSelectList () {
      const res = await getAllGropSelectList()
      this.allGroupList = res && res.Code === 200 ? res.Data : []
    },
    // 获取资产项目列表
    async fnGetAssetsProjectList () {
      const res = await getAssetsProjectList(this.queryForm)
      this.tableData = res.Code === 200 && res.Data ? res.Data.DataList : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetAssetsProjectList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetAssetsProjectList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetAssetsProjectList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetAssetsProjectList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      //  // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetAssetsProjectList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 点击删除按钮
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的资产项目，您确定要删除选中的资产项目吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i].Id)
            if (arr.length === this.multipleSelection.length) {
              obj.Id = arr.join(',')
              this.fnDelAssetsProject(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的资产项目！')
      }
    },
    // 删除资产项目请求
    async fnDelAssetsProject (obj) {
      // console.log('删除资产项目请求');
      // console.log(obj);
      const res = await delAssetsProject(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetAssetsProjectList()
    },
    // 打开新增对话框
    openAddPage () {
      this.addAndEditDialog = true
      this.titleDialog = '新增项目'
    },
    // 打开编辑对话框
    openEditPage (row) {
      this.addAndEditDialog = true
      this.titleDialog = '编辑项目'
      var that = this
      this.$nextTick(() => {
        that.addForm.Id = row.Id
        that.addForm.project_name = row.project_name
      })
    },
    // 关闭新增、编辑对话框时触发
    addAndEditDialogClose () {
      this.addAndEditDialog = false
      this.addForm.Id = ''
      this.$refs.addFormRef.resetFields()
      console.log(this.addForm)
    },
    // 新增、编辑资产项目
    sendForm () {
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          var obj = {}
          obj.project_name = this.addForm.project_name
          if (this.addForm.Id) {
            obj.Id = this.addForm.Id
            this.fnUpdateAssetsProject(obj)
          } else {
            this.fnAddAssetsProject(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增请求
    async fnAddAssetsProject (obj) {
      const res = await addAssetsProject(obj)
      console.log('新增请求---', res)
      if (res && res.Code === 200) {
        this.addAndEditDialog = false
        this.onSubmit()
      }
    },
    // 编辑请求
    async fnUpdateAssetsProject (obj) {
      const res = await updateAssetsProject(obj)
      console.log('编辑请求--', res)
      if (res && res.Code === 200) {
        this.addAndEditDialog = false
        this.fnGetAssetsProjectList()
      }
    },
    // 打开查看巡检项对话框
    async openLookInspection (row) {
      console.log(row)
      const res = await getAssetInspectionItemList({
        project_id: row.Id
      })
      this.inspectionData = res && res.Code === 200 ? res.Data : []
      this.isInspectionDialog = true
    },
    // 点击查看，预览图片
    showPreviewPicture (pic) {
      this.previewPictureList = pic.split(',')
      this.isShowPreviewPicture = true
    },
    // 关闭预览图片
    onClosePreviewPicture () {
      this.isShowPreviewPicture = false
      this.previewPictureList = []
    }
  }
}
</script>

<style scoped lang="less"></style>
